// src/pages/Home.js
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Home = () => (
  <>
    <Header />
    <div
  data-elementor-type="wp-page"
  data-elementor-id={14}
  className="elementor elementor-14"
>
  <div
    className="elementor-element elementor-element-4fd35355 e-flex e-con-boxed e-con e-parent e-lazyloaded"
    data-id="4fd35355"
    data-element_type="container"
  >
    <div className="e-con-inner">
      <div
        className="elementor-element elementor-element-5b6578d e-con-full e-flex e-con e-child"
        data-id="5b6578d"
        data-element_type="container"
      >
        <div
          className="elementor-element elementor-element-3fbb07eb elementor-widget elementor-widget-text-editor animated fadeIn"
          data-id="3fbb07eb"
          data-element_type="widget"
          data-settings='{"_animation":"fadeIn"}'
          data-widget_type="text-editor.default"
        >
          <div className="elementor-widget-container"></div>
        </div>
        <div
          className="elementor-element elementor-element-56a94433 e-flex e-con-boxed e-con e-child animated fadeIn"
          data-id="56a94433"
          data-element_type="container"
          data-settings='{"animation":"fadeIn","animation_delay":"200"}'
        >
          <div className="e-con-inner">
            <div
              className="elementor-element elementor-element-84cd222 elementor-widget__width-initial elementor-widget elementor-widget-jkit_animated_text"
              data-id="84cd222"
              data-element_type="widget"
              data-widget_type="jkit_animated_text.default"
            >
              <div className="elementor-widget-container">
                <div
                  className="jeg-elementor-kit jkit-animated-text jeg_module_14_2_671cf3e467143"
                  data-style="rotating"
                  data-text="Balaton"
                  data-rotate="blinds"
                  data-delay={2500}
                  data-letter-speed={100}
                >
                  <h1 className="animated-text">
                    <span className="normal-text style-color">
                      Politika privatnosti
                    </span>
                    <span className="normal-text style-color" />
                  </h1>
                </div>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-62a4ee00 e-con-full elementor-hidden-mobile elementor-hidden-tablet e-flex e-con e-child"
              data-id="62a4ee00"
              data-element_type="container"
            >
              <div
                className="elementor-element elementor-element-616c9703 elementor-widget__width-initial elementor-hidden-mobile elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                data-id="616c9703"
                data-element_type="widget"
                data-widget_type="divider.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-divider">
                    <span className="elementor-divider-separator"></span>
                  </div>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-2fbe37fe elementor-widget__width-initial elementor-hidden-mobile elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                data-id="2fbe37fe"
                data-element_type="widget"
                data-widget_type="divider.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-divider">
                    <span className="elementor-divider-separator"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    className="elementor-element elementor-element-2265ed1f e-flex e-con-boxed e-con e-parent e-lazyloaded"
    data-id="2265ed1f"
    data-element_type="container"
    data-settings='{"background_background":"classic"}'
  >
    <div className="e-con-inner">
      <div
        className="elementor-element elementor-element-353d65e5 e-con-full e-flex e-con e-child"
        data-id="353d65e5"
        data-element_type="container"
        style={{width: "100%"}}
      ><br /><br /><br /><br /><br />
                           <p>Ova politika privatnosti opisuje kako Top Check Travels ("mi", "naš" ili "sajt") prikuplja, koristi i štiti vaše lične podatke kada koristite naš sajt <a href="https://topchecktravels.com">topchecktravels.com</a>.</p>

<h2>1. Prikupljanje informacija</h2>
<p>Možemo prikupljati lične podatke kada posetite naš sajt, registrujete se, rezervišete uslugu ili nas kontaktirate. Prikupljeni podaci mogu uključivati vaše ime, e-mail adresu, telefonski broj i informacije o putovanju.</p>

<h2>2. Upotreba informacija</h2>
<p>Prikupljene informacije koristimo za:
    <ul>
        <li>Pružanje i unapređenje naših usluga.</li>
        <li>Komunikaciju sa vama o rezervacijama i korisničkoj podršci.</li>
        <li>Slanje informacija o promocijama, ponudama i ažuriranjima u vezi sa našim uslugama.</li>
    </ul>
</p>

<h2>3. Zaštita podataka</h2>
<p>Preduzimamo odgovarajuće tehničke i organizacione mere kako bismo zaštitili vaše podatke od neovlašćenog pristupa, promene, otkrivanja ili uništenja.</p>

<h2>4. Deljenje podataka</h2>
<p>Vaše podatke ne delimo sa trećim stranama osim u sledećim situacijama:
    <ul>
        <li>Kada je to neophodno za pružanje usluge (npr. saradnja sa partnerima za rezervacije).</li>
        <li>U skladu sa zakonskim obavezama.</li>
    </ul>
</p>

<h2>5. Kolačići</h2>
<p>Naš sajt koristi kolačiće za unapređenje korisničkog iskustva. Kolačići pomažu u analizi poseta sajtu i personalizaciji sadržaja. Možete onemogućiti kolačiće podešavanjem vašeg pretraživača.</p>

<h2>6. Prava korisnika</h2>
<p>Imate pravo da zatražite pristup, ispravku, brisanje ili ograničenje obrade vaših podataka. Takođe, imate pravo na prigovor na obradu i prenosivost podataka.</p>

<h2>7. Izmene politike privatnosti</h2>
<p>Top Check Travels zadržava pravo da povremeno ažurira ovu politiku privatnosti. Promene će biti objavljene na ovoj stranici.</p>


<p>Ako imate bilo kakvih pitanja ili želite dodatne informacije, kontaktirajte nas putem e-maila na <a href="mailto:info@topchecktravels.com">info@topchecktravels.com</a>.</p>
      </div>
    </div>
  </div>
  <div
    className="elementor-element elementor-element-9a0553c e-con-full e-flex e-con e-parent e-lazyloaded"
    data-id="9a0553c"
    data-element_type="container"
    data-settings='{"background_background":"classic"}'
  >
    <div
      className="elementor-element elementor-element-4f52514 elementor-widget elementor-widget-spacer"
      data-id="4f52514"
      data-element_type="widget"
      data-widget_type="spacer.default"
    >
      <div className="elementor-widget-container">
        <div className="elementor-spacer">
          <div className="elementor-spacer-inner" />
        </div>
      </div>
    </div>
  </div>
  <br />
  <br />
  <br />
  <br />
</div>


    <Footer />
  </>
);

export default Home;
