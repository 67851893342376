// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Contact from './pages/Contact';
import HomeWithoutCookieBox from './pages/HomeWithoutCookieBox';
import PrivacyWithoutCookieBox from './pages/PrivacyWithoutCookieBox';
import TermsWithoutCookieBox from './pages/TermsWithoutCookieBox';
import ContactWithoutCookieBox from './pages/ContactWithoutCookieBox';

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/politika-privatnosti" element={<Privacy />} />
      <Route path="/uslovi-koristenja" element={<Terms />} />
      <Route path="/kontakt" element={<Contact />} />
      <Route path="/page" element={<HomeWithoutCookieBox />} />
      <Route path="/page/politika-privatnosti" element={<PrivacyWithoutCookieBox />} />
      <Route path="/page/uslovi-koristenja" element={<TermsWithoutCookieBox />} />
      <Route path="/page/kontakt" element={<ContactWithoutCookieBox />} />
    </Routes>
  </Router>
);

export default App;
