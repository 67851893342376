// src/pages/Home.js
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CookieBox from '../components/CookieBox';  

const Home = () => (
  <>
    <CookieBox />
    <Header />
    <div
  data-elementor-type="wp-page"
  data-elementor-id={14}
  className="elementor elementor-14"
>
  <div
    className="elementor-element elementor-element-4fd35355 e-flex e-con-boxed e-con e-parent e-lazyloaded"
    data-id="4fd35355"
    data-element_type="container"
  >
    <div className="e-con-inner">
      <div
        className="elementor-element elementor-element-5b6578d e-con-full e-flex e-con e-child"
        data-id="5b6578d"
        data-element_type="container"
      >
        <div
          className="elementor-element elementor-element-3fbb07eb elementor-widget elementor-widget-text-editor animated fadeIn"
          data-id="3fbb07eb"
          data-element_type="widget"
          data-settings='{"_animation":"fadeIn"}'
          data-widget_type="text-editor.default"
        >
          <div className="elementor-widget-container"></div>
        </div>
        <div
          className="elementor-element elementor-element-56a94433 e-flex e-con-boxed e-con e-child animated fadeIn"
          data-id="56a94433"
          data-element_type="container"
          data-settings='{"animation":"fadeIn","animation_delay":"200"}'
        >
          <div className="e-con-inner">
            <div
              className="elementor-element elementor-element-84cd222 elementor-widget__width-initial elementor-widget elementor-widget-jkit_animated_text"
              data-id="84cd222"
              data-element_type="widget"
              data-widget_type="jkit_animated_text.default"
            >
              <div className="elementor-widget-container">
                <div
                  className="jeg-elementor-kit jkit-animated-text jeg_module_14_2_671cf3e467143"
                  data-style="rotating"
                  data-text="Balaton"
                  data-rotate="blinds"
                  data-delay={2500}
                  data-letter-speed={100}
                >
                  <h1 className="animated-text">
                    <span className="normal-text style-color">
                      Prestižni hoteli za nezaboravne
                    </span>
                    <span
                      className="dynamic-wrapper style-color"
                      style={{ color: "#ffd8ef" }}
                    >
                      trenutke
                    </span>
                    <span className="normal-text style-color" />
                  </h1>
                </div>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-62a4ee00 e-con-full elementor-hidden-mobile elementor-hidden-tablet e-flex e-con e-child"
              data-id="62a4ee00"
              data-element_type="container"
            >
              <div
                className="elementor-element elementor-element-616c9703 elementor-widget__width-initial elementor-hidden-mobile elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                data-id="616c9703"
                data-element_type="widget"
                data-widget_type="divider.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-divider">
                    <span className="elementor-divider-separator"></span>
                  </div>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-2fbe37fe elementor-widget__width-initial elementor-hidden-mobile elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                data-id="2fbe37fe"
                data-element_type="widget"
                data-widget_type="divider.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-divider">
                    <span className="elementor-divider-separator"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    className="elementor-element elementor-element-2265ed1f e-flex e-con-boxed e-con e-parent e-lazyloaded"
    data-id="2265ed1f"
    data-element_type="container"
    data-settings='{"background_background":"classic"}'
  >
    <div className="e-con-inner">
      <div
        className="elementor-element elementor-element-353d65e5 e-con-full e-flex e-con e-child"
        data-id="353d65e5"
        data-element_type="container"
      >
        <div
          className="elementor-element elementor-element-791b5955 e-con-full e-flex e-con e-child"
          data-id="791b5955"
          data-element_type="container"
        >
          <div
            className="elementor-element elementor-element-7cbdb52d elementor-widget elementor-widget-heading animated fadeIn"
            data-id="7cbdb52d"
            data-element_type="widget"
            data-settings='{"_animation":"fadeIn"}'
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <span>Gde prestiž sreće eleganciju</span>
              <h2 className="elementor-heading-title elementor-size-default">
                O nama
              </h2>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-19510267 elementor-widget elementor-widget-text-editor animated fadeIn"
            data-id={19510267}
            data-element_type="widget"
            data-settings='{"_animation":"fadeIn","_animation_delay":"200"}'
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p>
                Dobrodošli u Top Check Travel - Vašu destinaciju za otkrivanje
                najluksuznijih hotela u Srbiji. Mi smo vaš vodič kroz svet
                sofisticiranosti i prvoklasne udobnosti.{" "}
              </p>
              <p>
                Naša pažnja usmerena je ka izboru hotela koji ne samo da
                ispunjavaju visoke standarde kvaliteta i elegancije, već i
                pružaju jedinstveno iskustvo koje će obogatiti vaš boravak.
              </p>
              <p>
                Otkrijte s nama hotele koji nisu samo mesta za noćenje, već
                destinacije koje pružaju potpuno iskustvo – od izvrsne kuhinje,
                preko vrhunskih spa centara, do ekskluzivnih zabavnih sadržaja.
                Top Check Travel - Gde svaki boravak postaje nezaboravan
                doživljaj.
              </p>
            </div>
          </div>
        </div>
        <div
          className="elementor-element elementor-element-371ad0d7 elementor-widget-mobile__width-inherit elementor-widget elementor-widget-jkit_button animated fadeIn"
          data-id="371ad0d7"
          data-element_type="widget"
          data-settings='{"_animation":"fadeIn","_animation_delay":"600"}'
          data-widget_type="jkit_button.default"
        ></div>
      </div>
      <div
        className="elementor-element elementor-element-9453446 e-con-full e-flex e-con e-child"
        data-id={9453446}
        data-element_type="container"
      >
        <div
          className="elementor-element elementor-element-2ea6bb48 elementor-absolute elementor-widget elementor-widget-jkit_fun_fact animated fadeIn"
          data-id="2ea6bb48"
          data-element_type="widget"
          data-settings='{"_position":"absolute","_animation":"fadeIn","_animation_delay":"200"}'
          data-widget_type="jkit_fun_fact.default"
        >
          <div className="elementor-widget-container">
            <div className="jeg-elementor-kit jkit-fun-fact align-center hover-from-left elementor-animation- jeg_module_14_8_671cf3e474545">
              <div className="fun-fact-inner">
                <div className="content">
                  <div className="number-wrapper">
                    <span className="prefix" />
                    <span
                      className="number loaded"
                      data-value={8}
                      data-animation-duration={3500}
                    >
                      8
                    </span>
                    <span className="suffix" />
                    <sup className="super">+</sup>
                  </div>
                  <h2 className="title">godina</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="elementor-element elementor-element-4edca78b elementor-widget__width-auto elementor-widget elementor-widget-image animated fadeIn"
          data-id="4edca78b"
          data-element_type="widget"
          data-settings='{"_animation":"fadeIn"}'
          data-widget_type="image.default"
        >
          <div className="elementor-widget-container">
            <img
              loading="lazy"
              decoding="async"
              width={2560}
              height={1709}
              src="/files/hotel.webp"
              className="attachment-full size-full wp-image-482"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    className="elementor-element elementor-element-9a0553c e-con-full e-flex e-con e-parent e-lazyloaded"
    data-id="9a0553c"
    data-element_type="container"
    data-settings='{"background_background":"classic"}'
  >
    <div
      className="elementor-element elementor-element-4f52514 elementor-widget elementor-widget-spacer"
      data-id="4f52514"
      data-element_type="widget"
      data-widget_type="spacer.default"
    >
      <div className="elementor-widget-container">
        <div className="elementor-spacer">
          <div className="elementor-spacer-inner" />
        </div>
      </div>
    </div>
  </div>
  <div
    className="elementor-element elementor-element-58c23f85 e-flex e-con-boxed e-con e-parent e-lazyloaded"
    data-id="58c23f85"
    data-element_type="container"
    data-settings='{"background_background":"classic"}'
  >
    <div className="e-con-inner">
      <div
        className="elementor-element elementor-element-6804b551 e-con-full e-flex e-con e-child"
        data-id="6804b551"
        data-element_type="container"
      >
        <div
          className="elementor-element elementor-element-44519f7a elementor-widget elementor-widget-heading animated fadeIn"
          data-id="44519f7a"
          data-element_type="widget"
          data-widget_type="heading.default"
        >
          <div className="elementor-widget-container">
            <br />
            <br />
            <br />
            <br />
            <span>Sjajni smeštaji za Vas uz kazino</span>
            <h2 className="elementor-heading-title elementor-size-default">
              Najbolji kazino hoteli
            </h2>
          </div>
        </div>
        <div
          className="elementor-element elementor-element-1327bea3 elementor-widget__width-initial elementor-hidden-mobile elementor-widget elementor-widget-text-editor animated fadeIn"
          data-id="1327bea3"
          data-element_type="widget"
          data-widget_type="text-editor.default"
        >
          <div className="elementor-widget-container">
            <div
              id="KnM9nf"
              className="PZPZlf"
              data-attrid="tw-sourceArea"
              data-entityname="Google Translate"
            >
              <div id="tw-smenu" className="tw-menu">
                <span>
                  Bilo da tražite romantično bekstvo, vikend pun igara i zabave
                  sa prijateljima, ili poslovno okupljanje sa kolegama, mi imamo
                  savršeno mesto za vas.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="elementor-element elementor-element-d84519e e-con-full e-flex e-con e-child animated fadeIn"
        data-id="d84519e"
        data-element_type="container"
      >
        <div
          className="elementor-element elementor-element-40d9f05a elementor-widget elementor-widget-jkit_gallery"
          data-id="40d9f05a"
          data-element_type="widget"
          data-widget_type="jkit_gallery.default"
        >
          <div className="elementor-widget-container">
            <div
              className="jeg-elementor-kit jkit-gallery layout-overlay jeg_module_14_45_671cf3e4e56a5"
              data-grid="grid"
              data-id="jeg_module_14_45_671cf3e4e56a5"
              data-per-page={9}
              data-load-more={0}
              data-current-loaded={9}
              data-count-items={9}
              data-animation-duration={500}
              data-no-more="No More Item"
            >
              <div className="gallery-items">
                <div className="gallery-item-wrap">
                  <div className="grid-item">
                    <div className="thumbnail-wrap">
                      <img
                        loading="lazy"
                        decoding="async"
                        width={1663}
                        height={1000}
                        src="/files/metropol-palace-belgrade-photo-1.jpeg	"
                        className="attachment-full size-full"
                        source="library"
                      />
                    </div>
                    <div
                      className="caption-wrap style-overlay"
                      style={{ visibility: "visible", opacity: 1 }}
                    >
                      <div className="item-hover-bg" />
                      <div className="item-caption-over">
                        <div className="item-content">
                          <p
                            style={{
                              marginBottom: 5,
                              lineHeight: 1,
                              fontSize: 30
                            }}
                          >
                            Metropol Palace, Belgrade
                          </p>
                          <span style={{ fontSize: 18 }}>
                            Bulevar Kralja Aleksandra 69, Beograd, S
                          </span>
                          <a
                            href="https://www.booking.com/hotel/rs/metropol-palace.html"
                            type="button"
                            className="booknow"
                            target='_blank'
                          >
                            Bukiraj
                          </a>
                        </div>
                      </div>
                      <div className="caption-head" />
                      <div className="caption-category" />
                    </div>
                  </div>
                </div>
                <div className="gallery-item-wrap">
                  <div className="grid-item">
                    <div className="thumbnail-wrap">
                      <img
                        loading="lazy"
                        decoding="async"
                        width={1663}
                        height={1000}
                        src="/files/photo-city-hotel-11000-belgrade-21.jpeg"
                        className="attachment-full size-full"
                        source="library"
                      />
                    </div>
                    <div
                      className="caption-wrap style-overlay"
                      style={{ visibility: "visible", opacity: 1 }}
                    >
                      <div className="item-hover-bg" />
                      <div className="item-caption-over">
                        <div className="item-content">
                          <p
                            style={{
                              marginBottom: 5,
                              lineHeight: 1,
                              fontSize: 30
                            }}
                          >
                            Belgrade City Hotel
                          </p>
                          <span style={{ fontSize: 18 }}>
                            Savski trg 7, Savski Venac, 11000 Beograd, Srbija
                          </span>
                          <a
                            href="https://www.booking.com/hotel/rs/bg-city.html"
                            type="button"
                            className="booknow"
                            target='_blank'
                          >
                            Bukiraj
                          </a>
                        </div>
                      </div>
                      <div className="caption-head" />
                      <div className="caption-category" />
                    </div>
                  </div>
                </div>
                <div className="gallery-item-wrap">
                  <div className="grid-item">
                    <div className="thumbnail-wrap">
                      <img
                        loading="lazy"
                        decoding="async"
                        width={1663}
                        height={1000}
                        src="/files/294498606.jpg"
                        className="attachment-full size-full"
                        source="library"
                      />
                    </div>
                    <div
                      className="caption-wrap style-overlay"
                      style={{ visibility: "visible", opacity: 1 }}
                    >
                      <div className="item-hover-bg" />
                      <div className="item-caption-over">
                        <div className="item-content">
                          <p
                            style={{
                              marginBottom: 5,
                              lineHeight: 1,
                              fontSize: 30
                            }}
                          >
                            Mama Shelter Belgrade
                          </p>
                          <span style={{ fontSize: 18 }}>
                            Kneza Mihaila 54A, 11000 Beograd, Srbija
                          </span>
                          <a
                            href="https://www.booking.com/hotel/rs/mama-shelter-belgrade.html"
                            type="button"
                            className="booknow"
                            target='_blank'
                          >
                            Bukiraj
                          </a>
                        </div>
                      </div>
                      <div className="caption-head" />
                      <div className="caption-category" />
                    </div>
                  </div>
                </div>
                <div className="gallery-item-wrap">
                  <div className="grid-item">
                    <div className="thumbnail-wrap">
                      <img
                        loading="lazy"
                        decoding="async"
                        width={1663}
                        height={1000}
                        src="/files/208814318.jpg"
                        className="attachment-full size-full"
                        source="library"
                      />
                    </div>
                    <div
                      className="caption-wrap style-overlay"
                      style={{ visibility: "visible", opacity: 1 }}
                    >
                      <div className="item-hover-bg" />
                      <div className="item-caption-over">
                        <div className="item-content">
                          <p
                            style={{
                              marginBottom: 5,
                              lineHeight: 1,
                              fontSize: 30
                            }}
                          >
                            Hotel Centar No.1
                          </p>
                          <span style={{ fontSize: 18 }}>
                            1 Kolarčeva, 11000 Beograd, Srbija
                          </span>
                          <a
                            href="https://www.booking.com/hotel/rs/centar-no1.html"
                            type="button"
                            className="booknow"
                            target='_blank'
                          >
                            Bukiraj
                          </a>
                        </div>
                      </div>
                      <div className="caption-head" />
                      <div className="caption-category" />
                    </div>
                  </div>
                </div>
                <div className="gallery-item-wrap">
                  <div className="grid-item">
                    <div className="thumbnail-wrap">
                      <img
                        loading="lazy"
                        decoding="async"
                        width={1663}
                        height={1000}
                        src="/files/111746119.jpg"
                        className="attachment-full size-full"
                        source="library"
                      />
                    </div>
                    <div
                      className="caption-wrap style-overlay"
                      style={{ visibility: "visible", opacity: 1 }}
                    >
                      <div className="item-hover-bg" />
                      <div className="item-caption-over">
                        <div className="item-content">
                          <p
                            style={{
                              marginBottom: 5,
                              lineHeight: 1,
                              fontSize: 30
                            }}
                          >
                            Hotel Požega
                          </p>
                          <span style={{ fontSize: 18 }}>
                            Nikole Pašića 6, 31210 Požega, Srbija
                          </span>
                          <a
                            href="https://www.booking.com/hotel/rs/poa3-4ega.html"
                            type="button"
                            className="booknow"
                            target='_blank'
                          >
                            Bukiraj
                          </a>
                        </div>
                      </div>
                      <div className="caption-head" />
                      <div className="caption-category" />
                    </div>
                  </div>
                </div>
                <div className="gallery-item-wrap">
                  <div className="grid-item">
                    <div className="thumbnail-wrap">
                      <img
                        loading="lazy"
                        decoding="async"
                        width={1663}
                        height={1000}
                        src="/files/zdrelo-bazn.jpg"
                        className="attachment-full size-full"
                        source="library"
                      />
                    </div>
                    <div
                      className="caption-wrap style-overlay"
                      style={{ visibility: "visible", opacity: 1 }}
                    >
                      <div className="item-hover-bg" />
                      <div className="item-caption-over">
                        <div className="item-content">
                          <p
                            style={{
                              marginBottom: 5,
                              lineHeight: 1,
                              fontSize: 30
                            }}
                          >
                            Hotel Mlavske Terme Ždrelo
                          </p>
                          <span style={{ fontSize: 18 }}>
                            Ždrelo, 12309 Ždrelo, Srbija
                          </span>
                          <br />
                          <a
                            href="https://www.booking.com/hotel/rs/mlavske-terme-zdrelo.html"
                            type="button"
                            className="booknow"
                            target='_blank'
                          >
                            Bukiraj
                          </a>
                        </div>
                      </div>
                      <div className="caption-head" />
                      <div className="caption-category" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="load-more-items" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br />
  <br />
  <br />
  <br />
</div>


    <Footer />
  </>
);

export default Home;
