import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Footer = () => {

  const [isMenuActive, setIsMenuActive] = useState(false);
  const location = useLocation();

  if (location.pathname === '/page/' || location.pathname === '/page' || location.pathname === '/page/kontakt/' || location.pathname === '/page/kontakt' || location.pathname === '/page/politika-privatnosti/' || location.pathname === '/page/politika-privatnosti' || location.pathname === '/page/uslovi-koristenja/' || location.pathname === '/page/uslovi-koristenja') {
    return (
<footer>
  <div
    data-elementor-type="page"
    data-elementor-id={185}
    className="elementor elementor-185"
  >
    <div
      className="elementor-element elementor-element-5f44cf59 e-flex e-con-boxed e-con e-parent e-lazyloaded"
      data-id="5f44cf59"
      data-element_type="container"
      data-settings='{"background_background":"classic"}'
    >
      <div className="e-con-inner">
        <div
          className="elementor-element elementor-element-2096ca7d e-con-full e-flex e-con e-child"
          data-id="2096ca7d"
          data-element_type="container"
        >
          <div
            className="elementor-element elementor-element-32b7fbbc elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-heading"
            data-id="32b7fbbc"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h2 className="elementor-heading-title elementor-size-default">
                Spremni za nezaboravno iskustvo?
              </h2>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-58d3cf8b elementor-widget__width-initial elementor-widget elementor-widget-text-editor"
            data-id="58d3cf8b"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p>
                Rezervišite svoj luksuzni smeštaj danas i započnite avanturu
                koja vas neće razočarati. Otkrijte idealno mesto za odmor i
                uživanje uz Top Check Travel.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-353818ba e-flex e-con-boxed e-con e-parent e-lazyloaded"
      data-id="353818ba"
      data-element_type="container"
    >
      <div className="e-con-inner">
        <div
          className="elementor-element elementor-element-459c9f00 e-flex e-con-boxed e-con e-child"
          data-id="459c9f00"
          data-element_type="container"
        >
          <div className="e-con-inner">
            <div
              className="elementor-element elementor-element-602bff6d e-con-full e-flex e-con e-child"
              data-id="602bff6d"
              data-element_type="container"
            >
              <div
                className="elementor-element elementor-element-68065ffd e-con-full e-flex e-con e-child"
                data-id="68065ffd"
                data-element_type="container"
              >
                <div
                  className="elementor-element elementor-element-2dc1c88f e-con-full e-flex e-con e-child"
                  data-id="2dc1c88f"
                  data-element_type="container"
                >
                  <div
                    className="elementor-element elementor-element-18c4ab81 elementor-widget__width-auto elementor-widget elementor-widget-image"
                    data-id="18c4ab81"
                    data-element_type="widget"
                    data-widget_type="image.default"
                  >
                    <div className="elementor-widget-container">
                      <img
                        width={571}
                        height={192}
                        src="/files/logo.png"
                        className="attachment-full size-full wp-image-224"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-35e33dcb e-con-full e-flex e-con e-child"
                  data-id="35e33dcb"
                  data-element_type="container"
                >
                  <div
                    className="elementor-element elementor-element-cfea62f elementor-widget elementor-widget-heading"
                    data-id="cfea62f"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="elementor-heading-title elementor-size-default">
                        Linkovi
                      </h2>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-214a3ec9 elementor-widget__width-auto elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                    data-id="214a3ec9"
                    data-element_type="widget"
                    data-widget_type="icon-list.default"
                  >
                    <div className="elementor-widget-container">
                      <ul className="elementor-icon-list-items">
                        <li className="elementor-icon-list-item">
                          <a href="/page/politika-privatnosti">
                            <span className="elementor-icon-list-text">
                              Politika privatnosti
                            </span>
                          </a>
                        </li>
                        <li className="elementor-icon-list-item">
                          <a href="/page/uslovi-koristenja">
                            <span className="elementor-icon-list-text">
                              Uslovi korištenja
                            </span>
                          </a>
                        </li>
                        <li className="elementor-icon-list-item">
                          <a href="/page/kontakt">
                            <span className="elementor-icon-list-text">
                              Kontakt
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-7dc7c7d7 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
              data-id="7dc7c7d7"
              data-element_type="widget"
              data-widget_type="divider.default"
            >
              <div className="elementor-widget-container">
                <div className="elementor-divider">
                  <span className="elementor-divider-separator"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="elementor-element elementor-element-275482e2 e-con-full e-flex e-con e-child"
          data-id="275482e2"
          data-element_type="container"
        >
          <div
            className="elementor-element elementor-element-1451505d e-flex e-con-boxed e-con e-child"
            data-id="1451505d"
            data-element_type="container"
          >
            <div className="e-con-inner">
              <div
                className="elementor-element elementor-element-4ce6af80 elementor-widget__width-auto elementor-widget elementor-widget-text-editor"
                data-id="4ce6af80"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div className="elementor-widget-container">
                  <p>© 2024 Top Check Travel. Sva prava pridržana.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

    )
  }

  return (
<footer>
  <link rel="stylesheet" href="files/footer.css" />
  <div
    data-elementor-type="page"
    data-elementor-id={185}
    className="elementor elementor-185"
  >
    <div
      className="elementor-element elementor-element-5f44cf59 e-flex e-con-boxed e-con e-parent e-lazyloaded"
      data-id="5f44cf59"
      data-element_type="container"
      data-settings='{"background_background":"classic"}'
    >
      <div className="e-con-inner">
        <div
          className="elementor-element elementor-element-2096ca7d e-con-full e-flex e-con e-child"
          data-id="2096ca7d"
          data-element_type="container"
        >
          <div
            className="elementor-element elementor-element-32b7fbbc elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-heading"
            data-id="32b7fbbc"
            data-element_type="widget"
            data-widget_type="heading.default"
          >
            <div className="elementor-widget-container">
              <h2 className="elementor-heading-title elementor-size-default">
                Spremni za nezaboravno iskustvo?
              </h2>
            </div>
          </div>
          <div
            className="elementor-element elementor-element-58d3cf8b elementor-widget__width-initial elementor-widget elementor-widget-text-editor"
            data-id="58d3cf8b"
            data-element_type="widget"
            data-widget_type="text-editor.default"
          >
            <div className="elementor-widget-container">
              <p>
                Rezervišite svoj luksuzni smeštaj danas i započnite avanturu
                koja vas neće razočarati. Otkrijte idealno mesto za odmor i
                uživanje uz Top Check Travel.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-353818ba e-flex e-con-boxed e-con e-parent e-lazyloaded"
      data-id="353818ba"
      data-element_type="container"
    >
      <div className="e-con-inner">
        <div
          className="elementor-element elementor-element-459c9f00 e-flex e-con-boxed e-con e-child"
          data-id="459c9f00"
          data-element_type="container"
        >
          <div className="e-con-inner">
            <div
              className="elementor-element elementor-element-602bff6d e-con-full e-flex e-con e-child"
              data-id="602bff6d"
              data-element_type="container"
            >
              <div
                className="elementor-element elementor-element-68065ffd e-con-full e-flex e-con e-child"
                data-id="68065ffd"
                data-element_type="container"
              >
                <div
                  className="elementor-element elementor-element-2dc1c88f e-con-full e-flex e-con e-child"
                  data-id="2dc1c88f"
                  data-element_type="container"
                >
                  <div
                    className="elementor-element elementor-element-18c4ab81 elementor-widget__width-auto elementor-widget elementor-widget-image"
                    data-id="18c4ab81"
                    data-element_type="widget"
                    data-widget_type="image.default"
                  >
                    <div className="elementor-widget-container">
                      <img
                        width={571}
                        height={192}
                        src="/files/logo.png"
                        className="attachment-full size-full wp-image-224"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-35e33dcb e-con-full e-flex e-con e-child"
                  data-id="35e33dcb"
                  data-element_type="container"
                >
                  <div
                    className="elementor-element elementor-element-cfea62f elementor-widget elementor-widget-heading"
                    data-id="cfea62f"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h2 className="elementor-heading-title elementor-size-default">
                        Linkovi
                      </h2>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-214a3ec9 elementor-widget__width-auto elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                    data-id="214a3ec9"
                    data-element_type="widget"
                    data-widget_type="icon-list.default"
                  >
                    <div className="elementor-widget-container">
                      <ul className="elementor-icon-list-items">
                        <li className="elementor-icon-list-item">
                          <a href="/politika-privatnosti">
                            <span className="elementor-icon-list-text">
                              Politika privatnosti
                            </span>
                          </a>
                        </li>
                        <li className="elementor-icon-list-item">
                          <a href="/uslovi-koristenja">
                            <span className="elementor-icon-list-text">
                              Uslovi korištenja
                            </span>
                          </a>
                        </li>
                        <li className="elementor-icon-list-item">
                          <a href="/kontakt">
                            <span className="elementor-icon-list-text">
                              Kontakt
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-7dc7c7d7 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
              data-id="7dc7c7d7"
              data-element_type="widget"
              data-widget_type="divider.default"
            >
              <div className="elementor-widget-container">
                <div className="elementor-divider">
                  <span className="elementor-divider-separator"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="elementor-element elementor-element-275482e2 e-con-full e-flex e-con e-child"
          data-id="275482e2"
          data-element_type="container"
        >
          <div
            className="elementor-element elementor-element-1451505d e-flex e-con-boxed e-con e-child"
            data-id="1451505d"
            data-element_type="container"
          >
            <div className="e-con-inner">
              <div
                className="elementor-element elementor-element-4ce6af80 elementor-widget__width-auto elementor-widget elementor-widget-text-editor"
                data-id="4ce6af80"
                data-element_type="widget"
                data-widget_type="text-editor.default"
              >
                <div className="elementor-widget-container">
                  <p>© 2024 Top Check Travel. Sva prava pridržana.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

  )


};

export default Footer;
